import * as ACTIONS from 'src/redux/actionTypes';
import Country from 'src/models/Country';
import ProgressCountry from 'src/models/ProgressCountry';

interface IWindow extends Window {
  dataLayer: any[]
}
declare const window:IWindow

interface IReducerProps {
  [index: string]: any,
  name?: string,
  country?: Country | string,
  progressArray: ProgressCountry[] | [],
  score: number,
  viewPortWidth: number,
  numberOfSteps: number | undefined
}


const initialState: IReducerProps = {
  name : '',
  country: '',
  progressArray: [],
  score: 0,
  viewPortWidth: window.innerWidth,
  numberOfSteps: undefined
};

export default (state = initialState, action: IReducerProps) => {
  switch (action.type) {
    case ACTIONS.CHANGE_USERNAME: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: 'name is now set'});
      return {
        ...state,
        name: action.payload
      }
    }
    case ACTIONS.CHANGE_USERCOUNTRY: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload.name});
      window.dataLayer.push({'homeCountry':action.payload.name});
      return {
        ...state,
        country: action.payload
      }
    }
    case ACTIONS.UPDATE_SCORE: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      window.dataLayer.push({'latestScore': action.payload});
      return {
        ...state,
        score: action.payload
      }
    }
    case ACTIONS.CREATE_PROGRESS_ARRAY: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload.length});
      return {
        ...state,
        progressArray: action.payload
      }
    }
    case ACTIONS.UPDATE_PROGRESS_ARRAY: {
      return {
        ...state,
        progressArray: action.payload
      }
    }
    case ACTIONS.DEFINE_NUMBER_OF_STEPS: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        numberOfSteps: action.payload
      }
    }
    default:
      return state;
  }
};

