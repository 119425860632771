// import LinearProgress from '@material-ui/core/LinearProgress';
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import Confetti from 'react-confetti';

import { Globe } from "./globe";
import { BackFromAntipodeLogo } from 'src/assets/backfromantipodeLogo';

import { NameChip } from "./UserDetails/DetailChips";
import DetailsDialog from "./UserDetails/DetailsDialog";

// import IntegrationNotistack from "./notificationBar";
import { QuizDialog } from "./Quiz/quizDialog";
import { QuizEndDialog } from "./Quiz/quizEndDialog";
import { appDialogAction } from "src/redux/actions/appDialogAction";
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header:{
      display: "flex",
      alignItems: "center",
      // gridTemplateColumns: "repeat(1,4)",
      justifyContent: "center",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "stretch",
      padding: "10px",
    },
    center: { 
      width: "100%",
      display: "flex",
      // alignItems: "flex-end",
      justifyContent: "center",
      padding: "10px",
    },
    right: { 
      width: "100%",
      display: "flex",
      // alignItems: "flex-end",
      alignContent: "flex-end",
      justifyContent: "flex-end",
      // padding: "10px",
    },
    root: { 
      alignItems: "flex-end",
      display: "grid",
      gridTemplateColumns: "repeat(1,1)",
    },
    childGlobe: {
      gridRow: 1,
      gridColumn: 1,
      justifyContent: "center",
    },
    childButton: {
      gridRow: 1,
      gridColumn: 1,
      display: "flex",
      justifyContent: "center",
    },
  }),
);

interface IWindow extends Window {
  dataLayer: any[]
}
declare const window:IWindow

export const GlobePage: FunctionComponent = (props) => {
  const classes = useStyles();
    const openDialog = useSelector((state:any) => state.app.openDialog);
    const stateFocusCountry = useSelector((state:any) => state.game.focusCountry);
    const markerList = useSelector((state:any) => state.game.markerList);
    const gamePhase = useSelector((state:any) => state.game.gamePhase);
    const stateDarkTheme = useSelector((state:any) => state.app.darkTheme);

    window.dataLayer.push({'event':'pageview', 'pageName': document.location.href});

    const dispatch = useDispatch();

          return (
        <div>
           <Confetti width={window.innerWidth} height={window.innerHeight} gravity={0.1} recycle={false} numberOfPieces={gamePhase === 'end' ? 1750 : 0} />
            <Grid container className={classes.header}>
              <Grid item className={classes.right}>
                <NameChip onClick={() => {dispatch(appDialogAction(!openDialog))}} />
                <DetailsDialog open={openDialog}/>
              </Grid>
              <Grid item className={classes.center}>
                <BackFromAntipodeLogo width="550" height="100%" theme={stateDarkTheme ? 'dark' : 'light'}/> 
              </Grid>
            </Grid>

          
          <Grid container className={classes.root}>
            <Grid item className={classes.childButton}>
            <QuizDialog />
            <QuizEndDialog />
            </Grid>
            <Grid item className={classes.childGlobe}>
            <Globe focusCountry={stateFocusCountry} markers={markerList}/>
            </Grid>
          </Grid>
        </div>
          )
}
