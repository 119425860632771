import DistanceCountry from "src/models/DistanceCountry";

export const compare = (a:DistanceCountry,b:DistanceCountry) => {
    // Use toUpperCase() to ignore character casing
    const distanceA = a.distance;
    const distanceB = b.distance;
  
    let comparison = 0;
    if (distanceA > distanceB) {
      comparison = 1;
    } else if (distanceA < distanceB) {
      comparison = -1;
    }
    return comparison * -1;
  }

