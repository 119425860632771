import React, { Fragment,FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useSelector, useDispatch } from 'react-redux';

import { appInfoDialogAction } from "src/redux/actions/appInfoDialogAction";

const useStyles = makeStyles({
  chip: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    float: "right",
    width: 30,
    fontSize: "1rem",
    fontStyle: "italic",
    position: 'relative',
    border:"none",
  }
});

export const InfoDialog: FunctionComponent= () => {
  
  const classes = useStyles();
  const open = useSelector((state:any) => state.app.openInfoDialog);
  const dispatch = useDispatch();
  // const [open, setOpen] = React.useState<boolean>(firstOpen);
  
  const handleAction = () => {
    dispatch(appInfoDialogAction(!open))
  };

  return (
    <Fragment>
    <Chip 
        icon={<InfoOutlinedIcon />}
        className={classes.chip}
        variant="outlined"
        size="medium"
        clickable
        color="primary"
        onClick={handleAction}
        />
        <div>
      <Dialog open={open} onClose={handleAction} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">A Capital Topography Game</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your home country, we'll calculate the country farthest away from you.
            Can you guess the correct capitals for all the countries in the world and make your way back home?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </Fragment>
  );
}
