import * as ACTIONS from 'src/redux/actionTypes';
import OptionCountry from 'src/models/OptionCountry';


export const updateOptionsArray = (payload:OptionCountry[]) => {
    return {
      type: ACTIONS.UPDATE_OPTIONSARRAY,
      payload
    }
  }

export default updateOptionsArray;