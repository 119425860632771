import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
// import spongeReducer from "./reducers/spongeReducer";
import combineReducer from './reducers';


// function configureStore(state = { sponge:false }) {
//   return createStore(spongeReducer,state, applyMiddleware(logger));
// }

// export default configureStore;


export default createStore(combineReducer, applyMiddleware(logger));
