import React, { useState, FunctionComponent, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import ReactGlobe, { Marker } from 'react-globe';
import { useSelector } from 'react-redux';

import globeTexture from'src/assets/globe/globe.jpg';
import cloudsTexture from'src/assets/globe/clouds.png';
import backgroundTexture from'src/assets/globe/background.png';

interface Ievent {
  type: string,
  marker?: Marker,
  markerObjectID?: number,
  pointerEventPosition: object,
  previousCoordinates?:  Coordinates
};

function getTooltipContent(marker:Marker):string {
    return `Country: ${marker.name} `
  };

interface props {
  focusCountry: Marker,
  markers: Marker[]
}

export const Globe: FunctionComponent<props> = (props:props) => {
   
    // if viewPortWidth < 700; probably mobile phone and lower GPU-power.
    const viewPortWidth = useSelector((state:any) => state.user.viewPortWidth);
    const screenAdaptedValue = viewPortWidth > 700 ? true : false
        
    const [focusPoint, setFocusPoint] = useState<[number,number] | undefined>(undefined)
    console.log('focusPoint ', focusPoint)

    useEffect(() => {
      // https://reactjs.org/docs/hooks-reference.html#useeffect
      setFocusPoint(props.focusCountry ? props.focusCountry.coordinates : undefined);
          setTimeout(() => setFocusPoint(undefined),1000)
    }, [props.focusCountry]);
      
    return (
      <div style={{ width: '100vw', height: '50vh' }}>
        <ReactGlobe
          markers={props.markers}
          markerOptions={{
            getTooltipContent,
          }}

          // onDefocus={onDefocus}
          focus={focusPoint}
          // focus={props.focusCountry ? props.focusCountry.coordinates : undefined}
          focusOptions = {{distanceRadiusScale:2, enableDefocus:true}}
          initialCoordinates={props.focusCountry ? props.focusCountry.coordinates : undefined}
          globeOptions={{
            enableClouds:screenAdaptedValue, 
            enableGlow:screenAdaptedValue,
            texture:globeTexture,
            backgroundTexture: backgroundTexture,
            cloudsTexture: cloudsTexture}}
        />
      </div>
    );
  }
