import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from "react-redux";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProgressCountry from 'src/models/ProgressCountry';

import { gameEndDialogAction } from "src/redux/actions/gameEndDialogAction";

export const QuizEndDialog: FunctionComponent = () => {

      const open = useSelector((state:any) => state.game.openEndDialog); // moet nog gemaakt worden
      const numberOfSteps = useSelector((state:any) => state.user.numberOfSteps); // gebruiken voor de length (om max score te berekenen)
      const progressArray = useSelector((state:any) => state.user.progressArray);
      const totalScore = useSelector((state:any) => state.user.score);
      const homeCountry = useSelector((state:any) => state.user.country.name);

      const dispatch = useDispatch();
      
      const getFeedbackText = (numberOfSteps:number, totalScore:number, progressArray: ProgressCountry[]):string => {
            const maxScore = numberOfSteps * 3
            if(totalScore === maxScore && numberOfSteps === progressArray.length){
                return 'You Scored 100%, that is exceptionally good! \nEspecially considering you took the full route.'
            } else if((totalScore === maxScore && numberOfSteps !== progressArray.length)){
                return 'You Scored 100%, that is exceptionally good! \nNext time take a larger route and up that score!'
            } else if((totalScore > maxScore * 0.75)){
                return 'You Scored more than 75% of the points available. \nGreat job!'
            } else if((totalScore > maxScore * 0.5)){
                return 'You Scored more than half the points available. \nExtra practice helps, but already a good job done!'
            } else if((totalScore > maxScore * 0.25 && numberOfSteps === 10)){
                return 'You scored less than half the points available. \nMaybe try again some time soon.'
            } else if((totalScore > maxScore * 0.25)){
                return 'You Scored more 25% of the points availabe. \nMaybe try again some time soon and consider a shorter route.'
            } else if((totalScore < maxScore * 0.25)){
                return '\nYou scored less than 25% of the points availabe. \nGood your home now so you can go cry out at mommys lap.'
            } else {
                return '\nSomething went wrong. \nI hope you did alright with the game..'
            }
      }

      const handleClose = () => {
        dispatch(gameEndDialogAction(!open));
      };
      const handleAgain = () => {
      
        window.location.reload(false);
        // stateCountry.nam break't m
        // country: ""
        // name: ""
        // numberOfSteps: undefined
        // progressArray: []
        // score
        // show info dialog
      };
  
    return (
        <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"> You made it back to {homeCountry}</DialogTitle>
            <DialogContent>
                By taking {numberOfSteps} steps to get back home, you scored {totalScore} points!
                {getFeedbackText(numberOfSteps,totalScore,progressArray).split ('\n').map((item, i) => <p key={i}>{item}</p>)}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Back
              </Button>
              <Button onClick={handleAgain} color="primary">
                Start again
              </Button>
            </DialogActions>
           </Dialog> 
      
        </div>
    )
  }
