import * as ACTIONS from 'src/redux/actionTypes';
import Country from "src/models/Country";
import DistanceCountry from "src/models/DistanceCountry";
import ProgressCountry from "src/models/ProgressCountry";

import capitals from "src/assets/capitals";

import { distanceCalculator } from "src/helpers/distance";
import { compare } from "src/helpers/sortMethod";
import { getRandom } from  "src/helpers/getRandomFromArray";

export const createProgressArray = (payload:Country, numberOfSteps:number) => {
  
    // calculate distance between the selected country from the payload and all countries in the capitals-object
    const distanceArray:Array<DistanceCountry> = capitals.map((c:Country) => Object.assign(
      distanceCalculator(payload,c),{
        capital:c.capitalCity,
        name:c.name,
        latitude:+c.latitude,
        longitude:+c.longitude
      }
    ));
    
    // add game-variables to this array of objects
    const progressArray:Array<ProgressCountry> = distanceArray.map((c:DistanceCountry) => { return {...c, score:0,current:false, done:false}});
    
    // get a random sample if the user used the slider. if not, do nothing
    const finalProgressArray = numberOfSteps !== progressArray.length ? getRandom(progressArray, numberOfSteps) : progressArray
    
    // sort values so 0 will be the farthest away
    finalProgressArray.sort(compare);
       
    // set the farthest away capital to the current
    finalProgressArray[0].current = true

    return {
      type: ACTIONS.CREATE_PROGRESS_ARRAY,
      payload: finalProgressArray  
    }
  }

export default createProgressArray;