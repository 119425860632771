
import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from "react-redux";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { QuizQuestion } from './quizQuestion';
import { QuizMainButton } from './quizMainButton';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { gameDialogAction } from "src/redux/actions/gameDialogAction";

import ProgressCountry from "src/models/ProgressCountry";
import OptionCountry from "src/models/OptionCountry";

const generateFeedbackText = (lastChoice:OptionCountry, correctChoice:OptionCountry) => {
  if (lastChoice && lastChoice.isCorrect === true){
    return `Correct! ${lastChoice.capital} is indeed the capital of ${lastChoice.name}`
  } 
  if (lastChoice && lastChoice.isCorrect === false){
    return `Wrong.. ${lastChoice.capital} is not the capital of ${correctChoice.name}`
  }
  else {
    return 'Give It A Try'
  }   
};

  export const QuizDialog: FunctionComponent = () => {

      const open = useSelector((state:any) => state.game.openDialog);
      const progressArray = useSelector((state:any) => state.user.progressArray);
      const stateOptions = useSelector((state:any) => state.game.options);
      const options = stateOptions;

      // currentCountry's progress-information
      const currentCountry:ProgressCountry = progressArray.find((c:ProgressCountry) => c.current === true);
      const lastChoice = stateOptions.find((c:OptionCountry) => c.isLast === true);
      const correctChoice = stateOptions.find((c:OptionCountry) => c.isCorrect === true);
      
      
      const triesLeft = stateOptions.filter((c:OptionCountry) => c.isDisabled === false).length - 1;
      const done = !(lastChoice !== correctChoice && triesLeft > 0); 

      const getScore = () => {
        console.log(triesLeft);
        switch(triesLeft){
          case 1:
            return 3
          case 0:
            if(lastChoice === correctChoice){
              return 1
            } else {
              return 0
            }
          default:
            return 0
        }        
      }

      const feedbackText = generateFeedbackText(lastChoice, correctChoice);

      const focusCountry = useSelector((state:any) => state.game.focusCountry);
      const homeCountry = useSelector((state:any) => state.user.homeCountry);

      const dispatch = useDispatch();
      
      const handleClose = () => {
        dispatch(gameDialogAction(!open));
      };
  
    return (
        <div>
          <QuizMainButton 
            focusCountry={focusCountry} 
            homeCountry={homeCountry} 
            progressArray={progressArray} 
            currentCountry={currentCountry}
            />
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"> What's the Capital of {currentCountry ? currentCountry.name : 'error'}?</DialogTitle>
            <DialogContent>
              <QuizQuestion options={options} feedback={feedbackText} done={done} score={getScore()}/> 
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Back
              </Button>
            </DialogActions>
           </Dialog> 
      
        </div>
    )
  }
