import * as ACTIONS from 'src/redux/actionTypes';
import Country from "src/models/Country";

export const userCountryChange = (payload:Country) => {
  return {
      type: ACTIONS.CHANGE_USERCOUNTRY,
      payload
    }
  }

export default userCountryChange;