
import loadExternalJS from 'src/utils/loadExternalJS';

interface IWindow extends Window {
    dataLayer: any[]
}

declare const window:IWindow


export default () => {
  loadExternalJS("https://www.googletagmanager.com/gtm.js?id=GTM-W4V2T3R");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'gtm.start':new Date().getTime(),event:'gtm.js'});
}
