import Country from "src/models/Country";
import DistanceCountry from "src/models/DistanceCountry";

export const distanceCalculator = (homeCountry:Country, toCountry:Country) => {
    
    const toRadians = (coordinate:number) => coordinate * Math.PI / 180;
    
    const lat1:number = parseFloat(homeCountry.latitude);
    const lon1:number = parseFloat(homeCountry.longitude);
    const lat2:number = parseFloat(toCountry.latitude);
    const lon2:number = parseFloat(toCountry.longitude);

    const R = 6371e3; // metres
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians((lat2-lat1));
    const Δλ = toRadians((lon2-lon1));

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    // distance in meters:
    const d = R * c;
    const km = d / 1000.0 

    let returnObject:DistanceCountry = {
        'iso2Code': toCountry.iso2Code, 
        'capital': toCountry.capitalCity, 
        'name': toCountry.name, 
        'latitude': +toCountry.latitude, 
        'longitude': +toCountry.longitude, 
        'distance' : km
    };
    
    return returnObject
}