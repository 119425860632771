import * as ACTIONS from 'src/redux/actionTypes';

interface IWindow extends Window {
  dataLayer: any[]
}
declare const window:IWindow

interface IReducerProps {
  [index: string]: any;
}

const initialState: IReducerProps = {
  options: [],
  openDialog: false,
  openEndDialog: false,
  focusCountry: undefined,
  markerList: [],
  gamePhase: 'start'

};

export default (state = initialState, action: IReducerProps) => {
  switch (action.type) {
    case ACTIONS.UPDATE_OPTIONSARRAY: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        options: action.payload
      };
    } 
    case ACTIONS.OPEN_GAME_DIALOG: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        openDialog: action.payload
      };
    } 
    case ACTIONS.OPEN_GAME_END_DIALOG: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        openEndDialog: action.payload
      };
    } 
    case ACTIONS.UPDATE_FOCUSCOUNTRY: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload.name});
      return {
        ...state,
        focusCountry: action.payload
      };
    } 
    case ACTIONS.UPDATE_GAME_PHASE: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        gamePhase: action.payload
      };
    }
    case ACTIONS.ADD_TO_MARKERLIST: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload.name});
      return {
        ...state,
        markerList: [...state.markerList,action.payload]
      };
    } 
    case ACTIONS.RESET_STATE: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: 'state reset'});
      return {
        initialState
      };
    } 
    default: // need this for default case
      return state 
  }
}