export const shuffle = (array:number[]) => {
  // Fisher–Yates Shuffle
  // source: https://bost.ocks.org/mike/shuffle/
  
    var m = array.length, t, i;
  
    // While there remain elements to shuffle…
    while (m) {
  
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
  
      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
  
    return array;
  }
export default shuffle