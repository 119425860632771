import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import NextButton from "./quizQuestionNextButton";

import { updateOptionsArray } from "src/redux/actions/gameUpdateOptionsArray";

import OptionCountry from "src/models/OptionCountry";

import {createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const optionChoice = (index:number, options:OptionCountry[], dispatch:any, done:boolean) => () => {

  let newOptions = options.map((c:OptionCountry) => {
    c.isLast = false;
    return c
  });
  newOptions[index].isDisabled = true;
  newOptions[index].isLast = true;
  
  dispatch(updateOptionsArray(newOptions))
}

interface props {
    options: OptionCountry[],
    feedback: string,
    done: boolean,
    score: number
}

export const QuizQuestion: FunctionComponent<props> = (props:props) => {
    const feedback = props.feedback;
    const options = props.options;
    const [done, setDone] = useState(props.done);

    // This useEffect will cause a rerender when props.done changes.
    useEffect(() => { 
      setDone(props.done);
      console.log('effect used');
     }, [props.done]);

    const dispatch = useDispatch();

    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      margin: {
        margin: theme.spacing(1),
      },
      root: {
        justifyContent: 'center'
    }
    }),
  );
  const classes = useStyles();

    return (
      <Fragment>  
        <DialogContentText>
          {feedback}
         
        </DialogContentText>
        {options.map((option:OptionCountry, index:number) => 
          <Button key={option.iso2Code} disabled={option.isDisabled} onClick={optionChoice(index, options,dispatch, done)} variant="outlined" color="primary" className={classes.margin}>
            {option.capital}
          </Button>)}
          <Box display="flex" alignItems="center" justifyContent="center">
          <NextButton done={done} score={props.score}/>
          {/* <Button variant="contained" color="primary" className={classes.root}>Next Question</Button> */}
          </Box>
      </Fragment>
    )
}
export default QuizQuestion;
