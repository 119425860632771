import * as ACTIONS from 'src/redux/actionTypes';

// interface IuserNameChange {
//     name: string
// }

export const userNameChange = (payload:string) => {
    return {
      type: ACTIONS.CHANGE_USERNAME,
      payload
    }
  }

export default userNameChange;