import * as ACTIONS from 'src/redux/actionTypes';
import { Marker } from 'react-globe';


export const updateFocusCountry = (payload:Marker|undefined) => {
    return {
      type: ACTIONS.UPDATE_FOCUSCOUNTRY,
      payload
    }
  }

export default updateFocusCountry;