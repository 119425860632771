import * as ACTIONS from 'src/redux/actionTypes';

interface IWindow extends Window {
  dataLayer: any[]
}
declare const window:IWindow

interface IReducerProps {
  [index: string]: any;
}

const initialState: IReducerProps = {
  sponge: false,
  openDialog: true,
  openInfoDialog: true,
  darkTheme: false
};

export default (state = initialState, action: IReducerProps) => {
  switch (action.type) {
    case ACTIONS.SPONGE: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        sponge: action.payload,
      };
    }
    case ACTIONS.OPEN_DETAILS_DIALOG: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        openDialog: action.payload,
      };
    }
    case ACTIONS.OPEN_INFO_DIALOG: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        openInfoDialog: action.payload
      };
    } 
    case ACTIONS.TOGGLE_THEME: {
      window.dataLayer.push({'event':'gameEvent', 'action': action.type, 'pageName': document.location.href, payload: action.payload});
      return {
        ...state,
        darkTheme: action.payload
      };
    } 
    default:
      return state;
  }
};

