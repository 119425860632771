import React, { FunctionComponent } from "react";
import { ReactComponent as LogoDark } from 'src/assets/backfromantipode_light.svg';
import { ReactComponent as LogoLight } from 'src/assets/backfromantipode_dark.svg';

interface props {
    theme: 'dark'| 'light',
    width: string,
    height: string
}

export const BackFromAntipodeLogo: FunctionComponent<props> = (props:props) => {

    if(props.theme === 'dark'){
        return (
            <LogoDark width={props.width} height={props.height}/>
        )
    }
    else {
    //props.theme === 'light'
        return (
            <LogoLight width={props.width} height={props.height}/>
        )
    }

}
