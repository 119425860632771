import Country from "src/models/Country";
import { Marker } from "react-globe";
import getRandomColor from "./getRandomColor";


export function parseCountryToMarker(country:Country):Marker{
    return {
      id: country.id,
      color: getRandomColor(),
      city : country.capitalCity,
      name: country.name,
      coordinates: [+country.latitude,+country.longitude], //+ to make string a number
      value: Math.floor(Math.random() * 100)
    }
  };

  export default parseCountryToMarker