import * as ACTIONS from 'src/redux/actionTypes';
import ProgressCountry from "src/models/ProgressCountry";

export const updateProgressArray = (payload:ProgressCountry[]) => {
    
    return {
      type: ACTIONS.UPDATE_PROGRESS_ARRAY,
      payload: payload
    }
  }

export default updateProgressArray;