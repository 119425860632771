import * as ACTIONS from 'src/redux/actionTypes';
import GamePhase from "src/models/GamePhase";




// const progressArray:ProgressCountry[] = useSelector((state:any) => state.user.progressArray);
// const focusCountry:Country = useSelector((state:any) => state.game.focusCountry);
// dispatch(updateGamePhase({focusCountry,homeCountry,progressArray}));




// Main Premise: 
// bij start van game laat je de homeCountry zien met marker en start the game tekst om daarna na naar de eerste in de progressArray te gaan.
// daarna is onclick een normale define-optoins + normale tekst 'next capital'.

export const updateGamePhase = (payload:GamePhase) => {
    return {
      type: ACTIONS.UPDATE_GAME_PHASE,
      payload
    }
  }

export default updateGamePhase;