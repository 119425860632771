import React, {FunctionComponent} from "react";
import { useSelector, useDispatch} from "react-redux";

import { spongeToggleAction } from "src/redux/actions/spongeToggleAction";

import vete from "src/images/vete-a-la-verga.jpg";

interface IWindow extends Window {
    dataLayer: any[]
}
declare const window:IWindow

export const HomePage:FunctionComponent = () => {
    const sponge = useSelector((state:any) => state.app.sponge);
    const dispatch = useDispatch();
    window.dataLayer.push({'event':'pageview', 'pageName': document.location.href});
    return (
        <div>
            <div id="h1" onClick={() => dispatch(spongeToggleAction(!sponge))}>
            alaverga
            </div>
            <br></br>
            <img 
            src={vete}
            className={
            "sponge" + 
            (sponge ? " sponge-visible":"")
            }
            alt="vere a la verga"
            />
        </div>
)
}