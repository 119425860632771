import { combineReducers } from 'redux';
import appReducer from './app.reducer';
import userReducer from './user.reducer';
import gameReducer from './game.reducer';

export default combineReducers({
  app: appReducer,
  user: userReducer,
  game: gameReducer
});
