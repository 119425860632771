import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from "react-redux";

import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

import Country from "src/models/Country";
import ProgressCountry from "src/models/ProgressCountry";
import GamePhase from "src/models/GamePhase";  

import { gameDialogAction } from "src/redux/actions/gameDialogAction";
import { gameEndDialogAction } from "src/redux/actions/gameEndDialogAction";
import { updateOptionsArray } from "src/redux/actions/gameUpdateOptionsArray";
import { updateGamePhase } from "src/redux/actions/gameUpdateGamePhase";
import defineOptions from 'src/helpers/defineQuizOptions';
import DistanceCountry from 'src/models/DistanceCountry';
import { defineGameState } from 'src/helpers/defineGameState';
import addToMarkerList from 'src/redux/actions/gameAddToMarkerList';
import { parseDistanceCountryToMarker } from 'src/helpers/parseDistanceCountryToMarker';
import updateFocusCountry from 'src/redux/actions/gameUpdateFocusCountry';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    justifyContent: 'center'
}
}),
);

const generateGameButtonText = (gamePhase:GamePhase) => {
    if(gamePhase === 'start'){
      return "Start The Game!"
    }
    if(gamePhase === 'end'){
      return "It appears you're done!"
    }
    else {
      return "Guess the next Capital!"
    }

  }

  interface props {
      focusCountry:Country,
      homeCountry:Country,
      progressArray:ProgressCountry[],
      currentCountry:DistanceCountry
  }
  export const QuizMainButton: FunctionComponent<props> = (props:props) => {
    // components are not automatically rerendered when props update.
    // Therefore we use the focusCountry-prop, which changes if any of the other props change.
    // This prop triggers the useEffect(), which rerenders the component.
    // THerefore useState is only necessary for this prop and not for the other 2.

    const [focusCountry,setFocusCountry] = useState<Country>(props.focusCountry);
    // const [homeCountry,setHomeCountry] = useState<Country>(props.homeCountry);
    // const [progressArray,setProgressArray] = useState<ProgressCountry[]>(props.progressArray);

    useEffect(() => { 
      setFocusCountry(props.focusCountry);
      // setHomeCountry(props.homeCountry);
      // setProgressArray(props.progressArray);
      // console.log('fCountry effect used');
     }, [props.focusCountry]);

    const dispatch = useDispatch();

    const gameState = defineGameState({focusCountry, homeCountry: props.homeCountry, progressArray: props.progressArray});
    if(gameState === 'end'){
      dispatch(updateGamePhase('end'));
    };

    const classes = useStyles();
    const gamePhase = useSelector((state:any) => state.game.gamePhase);
    const gameButtonText = generateGameButtonText(gamePhase);

    const handleOpen = (gamePhase:GamePhase) => {
      if(gamePhase === 'start'){
        dispatch(updateGamePhase('ongoing'));
        dispatch(updateFocusCountry(parseDistanceCountryToMarker(props.currentCountry)));
        dispatch(addToMarkerList(parseDistanceCountryToMarker(props.currentCountry)));
      }
      else if(gamePhase === 'end'){
        // dispatch infoDialog with scored points and performance.
        dispatch(gameEndDialogAction(true));
      }
      else {
        // when phase is 'ongoing'
        dispatch(gameDialogAction(true));      
        const options = defineOptions(props.currentCountry, props.progressArray) || [];
        console.log(options);
        dispatch(updateOptionsArray(options));
      }
    }

    return (
    <div>
        <Button variant="contained" color="secondary" onClick={() => handleOpen(gamePhase)} className={classes.margin}>
            {gameButtonText}
        </Button>
    </div>
    )
  }
   