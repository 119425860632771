import ProgressCountry from "src/models/ProgressCountry";
import OptionCountry from "src/models/OptionCountry";

import generateNewNumber from "./generateNewNumber";
import shuffle from "./shuffle";
import DistanceCountry from "src/models/DistanceCountry";

// INBOUWEN: ALS max-1 (das de laatste index) 2 weg is, pak dan de laatste 2.

export const defineOptions = (currentProgressCountry:DistanceCountry, progressArray:ProgressCountry[]) => {
    const progressArrayLength = progressArray.length;
    // takes the current country from the progressArray + the full capitals object
    // returns an array with 3 optionCountry-objects, for the current questionnaire, of which 1 is the correct country.

    if(!currentProgressCountry){return}

    // get index in the progressArray (to find the other options)
    const currentCountryIndex = progressArray.findIndex((country:ProgressCountry) => country.iso2Code === currentProgressCountry.iso2Code);

    // function to get random indexes for the other 2 options
    // numberOfReturnIndexes is overriden by the max options (first to last question can only have 2)
    const getRandomIndexes = (min:number, numberOfReturnIndexes:number, range:number, maxValue=progressArrayLength) => {

        // if we reach the end of the game the maxValue will be the last index.
        const max = currentCountryIndex+range > maxValue - 1 ? maxValue - 1 : currentCountryIndex+range;

        // if we reach the end of the game, the numberOfReturnIndexes might be larger than the available values, causing stack overflow.
        // if the max from above (let's say 151) - currentCountryIndex (let's say 150) < 2, then return (151-150)+1
        // because there are only 2 countries left, so 2 indexes to return, and 2 buttons to create.
        const finalNumberOfReturnIndexes = max - currentCountryIndex < 2 ? 2 : numberOfReturnIndexes;

        // indexArray where we collect the unique indexes.
        let indexArray = [currentCountryIndex];
        console.log(indexArray)
        // eslint-disable-next-line
        // for(const i in [...Array(finalNumberOfReturnIndexes).keys()]){
        while(indexArray.length < finalNumberOfReturnIndexes){
            
        const newNumber = generateNewNumber(min, max,indexArray);

        // if generateNewNumber return undefined, this means no unique numbers were available in this range.
        // then fallback on the currentCountryIndex;
        const returnNumber = newNumber ? newNumber : currentCountryIndex;
        indexArray.push(returnNumber);
        }

        // shuffle array to make sure the correct one is not always the left one:
        const ShuffledIndexArray = shuffle(indexArray);
        return ShuffledIndexArray;
    }

    const indexes = getRandomIndexes(currentCountryIndex,3,10);
    const options:OptionCountry[] = indexes.map((index:number) => { 
        return {
        'iso2Code': progressArray[index].iso2Code,  
        'name' : progressArray[index].name,
        'capital': progressArray[index].capital,
        'isCorrect':progressArray[index].current,
        'isDisabled':false,
        'isLast': false,
    }})
    return options
};
export default defineOptions;