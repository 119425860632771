const randomIntFromRange = (min:number, max:number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  
export const generateNewNumber = (min:number,max:number, uniquesArray:number[]):number =>{
    let randomInt = randomIntFromRange(min,max);
    console.log(randomInt);
    if(uniquesArray.includes(randomInt)){
        console.log('already found', randomInt)
        return generateNewNumber(min,max,uniquesArray);
    } 
        return randomInt
}
export default generateNewNumber;
