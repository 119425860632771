import * as ACTIONS from 'src/redux/actionTypes';
// import Country from 'src/models/Country';
import { Marker } from 'react-globe';


export const addToMarkerList = (payload:Marker|undefined) => {
    return {
      type: ACTIONS.ADD_TO_MARKERLIST,
      payload
    }
  }

export default addToMarkerList;