import * as ACTIONS from 'src/redux/actionTypes';

export const defineNumberOfSteps = (payload:number) => {
    
    return {
      type: ACTIONS.DEFINE_NUMBER_OF_STEPS,
      payload: payload
    }
  }

export default defineNumberOfSteps;