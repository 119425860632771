export const SPONGE = "sponge"; // used for spongeShow and spongeHide
export const TOGGLE_THEME = 'TOGGLE_THEME';

export const OPEN_DETAILS_DIALOG = "OPEN_DETAILS_DIALOG";
export const OPEN_INFO_DIALOG = "OPEN_INFO_DIALOG";
export const CHANGE_USERNAME = "CHANGE_USERNAME";
export const CHANGE_USERCOUNTRY = "CHANGE_USERCOUNTRY";

export const OPEN_GAME_DIALOG = "OPEN_GAME_DIALOG";
export const OPEN_GAME_END_DIALOG = "OPEN_GAME_END_DIALOG";
export const UPDATE_SCORE = "UPDATE_SCORE";
export const UPDATE_GAME_PHASE = "UPDATE_GAME_PHASE";
export const UPDATE_OPTIONSARRAY = "UPDATE_OPTIONSARRAY";

export const UPDATE_FOCUSCOUNTRY = "UPDATE_FOCUSCOUNTRY";
export const ADD_TO_MARKERLIST = "ADD_TO_MARKERLIST";

export const CREATE_PROGRESS_ARRAY = "CREATE_PROGRESS_ARRAY";
export const UPDATE_PROGRESS_ARRAY = "UPDATE_PROGRESS_ARRAY";
export const DEFINE_NUMBER_OF_STEPS = "DEFINE_NUMBER_OF_STEPS";

export const RESET_STATE = "RESET_STATE";


