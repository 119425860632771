import DistanceCountry from "src/models/DistanceCountry"
import { Marker } from "react-globe"
import getRandomColor from "./getRandomColor"

export function parseDistanceCountryToMarker(country:DistanceCountry|undefined):Marker|undefined{
  
  // if user reached end of game, the currentCountry will be undefined.
  // Then return undefined.
  if(!country){
    return
  }

    return {
      id: country.iso2Code,
      color: getRandomColor(),
      city : country.capital,
      name: country.name,
      coordinates: [+country.latitude,+country.longitude], //+ to make string a number
      value: 100
    }
};

export default parseDistanceCountryToMarker