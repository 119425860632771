import ProgressCountry from "src/models/ProgressCountry";

export const defineNewQuizState = (payload:ProgressCountry[]) => {

    const currentIndex = payload.findIndex((country:ProgressCountry) => country.current);
    const newIndex = currentIndex + 1;
    const maxIndex = payload.length - 1;

    let newProgressArray = payload.map((c:ProgressCountry) => {
        c.current = false;
        return c
      });
    
    if(!(newIndex > maxIndex)){
        newProgressArray[newIndex].current = true
    };
    
    const newCurrentCountry: ProgressCountry|undefined = newProgressArray.find((country:ProgressCountry) => country.current === true)
    
    return {
      newProgressArray,
      newCurrentCountry
    }
  }

export default defineNewQuizState;