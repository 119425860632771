import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { Box } from '@material-ui/core';

interface Props {
  onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
}

export const NameChip: FunctionComponent<Props> = (props) => {
  const stateName = useSelector((state:any) => state.user.name);
  const stateScore = useSelector((state:any) => state.user.score);

  const stateCountry = useSelector((state:any) => state.user.country);
  
  const useStyles = makeStyles({
    chipName: {
      margin: 0,
      right: 20,
      top: 20,
      left: 'auto',
      position: 'inherit',
      
    },
    chipScore: {
      margin: 0,
      right: 20,
      top: 57,
      left: 'auto',
      position: 'inherit',
    },
    chipName2: {
      display: "flex",
      justifyContent: "flex-end",
      
    },
    chipScore2: {
      display: "flex",
      justifyContent: "flex-end",
    },
    check: {
      display: 'flex',
      flexDirection: 'column',
      // backgroundColor: 'red',
      alignItems:"flex-end"
        // p:1,
        // m:1
    },
  });
  const classes = useStyles();

  const flagIcon = (stateCountry:any) => {
    if(stateCountry !== ''){
      try {
       return `https://www.countryflags.io/${stateCountry.iso2Code.toLowerCase()}/flat/48.png`
      } catch {
        console.log('countryflags.io api-request did not succeed');
        return "src/white_24.png"
      }
    } else {
      return "src/white_24.png"
    }
 };
    
  return (
    <div>
      <Box  className={classes.check}>
      <Chip
        className={classes.chipName}
        variant="default"
        size="medium"
        avatar={<Avatar style={{border: 0, objectFit: 'cover', width:32}} alt={stateCountry.name} src={flagIcon(stateCountry)} />}
        label={stateName !== '' ? stateName : 'Click Here to Enter Your Name'}
        clickable
        color="primary"
        onClick={props.onClick}
      />
      <Chip 
        className={classes.chipScore}
        variant="default"
        size="medium"
        label={`Current Score: ${stateScore}`}/>
      </Box>
    </div>
  );
}
