import Country from "src/models/Country";
import ProgressCountry from "src/models/ProgressCountry";
import GamePhase from "src/models/GamePhase";

// Main Premise: 
// bij start van game laat je de homeCountry zien met marker en start the game tekst om daarna na naar de eerste in de progressArray te gaan.
// daarna is onclick een normale define-optoins + normale tekst 'next capital'.

interface Payload {
  focusCountry:Country, 
  homeCountry:Country, 
  progressArray:ProgressCountry[]
}


// Main Premise: 
// bij start van game laat je de homeCountry zien met marker en start the game tekst om daarna na naar de eerste in de progressArray te gaan.
// daarna is onclick een normale define-optoins + normale tekst 'next capital'.

export const defineGameState = ({focusCountry,homeCountry,progressArray}:Payload):GamePhase => {
  console.log({focusCountry,homeCountry,progressArray})
  const currentIndex = progressArray.findIndex((c:ProgressCountry) => c.current === true);
  // if focus is on homeCountry and currentIndex < 1 (only possible when just started)
  if((focusCountry === homeCountry && currentIndex < 1) || progressArray.length === 0){
    return "start"
}

  if(currentIndex === progressArray.length -1){
      return "end"
  }
  
  else {
      return "ongoing"
  }
}

export default defineGameState;