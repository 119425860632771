export default function loadExternalJS (TARGET_URL: string) {
    const scriptTag = document.createElement('script');
    try {
    scriptTag.type = "text/javascript";
    scriptTag.async=true;
    scriptTag.src = TARGET_URL;
    document.getElementsByTagName('head')[0].appendChild(scriptTag);
    } catch(e) {
        console.log(`An error occurred: ${e}`)
    }
};