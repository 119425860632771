import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import PublicIcon from '@material-ui/icons/Public';

import Brightness4Icon from '@material-ui/icons/Brightness4';

// import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Omit } from '@material-ui/types';
import { useDispatch } from 'react-redux';
import appToggleTheme from 'src/redux/actions/appToggleTheme';

const useStyles = makeStyles({
    menu: {
      width: 230,
    },
  });

interface MenuItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
  }

  function MenuItemLink(props: MenuItemLinkProps) {
    const { icon, primary, to } = props;
  
    const renderLink = React.useMemo(
      () =>
        React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>(
          (itemProps, ref) => (
            // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
            // See https://github.com/ReactTraining/react-router/issues/6056
            <RouterLink to={to} {...itemProps} innerRef={ref} />
          ),
        ),
      [to],
    );
    return (
          <MenuItem button component={renderLink}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            {primary}
          </MenuItem>
      );
    }

    interface props {
      darkTheme: boolean
    }
export default function SimpleMenu(props:props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.menu}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItemLink to='/' primary='To Home' icon={<HomeIcon />} />
        <MenuItemLink to='/globe' primary='To Globe' icon={<PublicIcon />} />
        <MenuItem button onClick={() => dispatch(appToggleTheme(!props.darkTheme))}>
        <ListItemIcon>{<Brightness4Icon />}</ListItemIcon>
          {props.darkTheme ? "Light Theme" : "Dark Theme"}
        </MenuItem>
      </Menu>
    </div>
  );
}