import "./App.css";

import React, { FunctionComponent, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
  } from "react-router-dom";
import gtm from "./modules/gtm";

import SimpleMenu from "./components/Main/menu";
import { GlobePage } from "./components/Globe/globePage";
import { HomePage } from "./components/Home/homePage";
import { createMuiTheme, MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import appToggleTheme from "./redux/actions/appToggleTheme";

interface IWindow extends Window {
  dataLayer: any[]
}
declare const window:IWindow

type MyProps = { [customField: string]: any };
export const App:FunctionComponent<MyProps> = (props:MyProps) => {

  const dispatch = useDispatch()
    const stateDarkTheme = useSelector((state:any) => state.app.darkTheme);
    window.dataLayer = window.dataLayer || [];
    const theme = React.useMemo(
      () =>
        createMuiTheme({
          // font = https://www.1001fonts.com/gladish-font.html

          // overrides: {
          // //  // https://material-ui.com/customization/globals/#css  

          //   MuiButton: {
          //     outlined: {
          //       backgroundColor: "rgba(255, 255, 255, 0.7)"//fade('white', 0.18)
          //     },
          //   }
          // },
          palette: {
            // light: https://colorhunt.co/palette/189837
            // dark: https://colorhunt.co/palette/178936

            type: stateDarkTheme ? 'dark' : 'light',
            primary: {
              main: stateDarkTheme ? "rgb(166, 212, 250)" : "rgb(100, 141, 174)",
              contrastText: "rgba(0, 0, 0, 0.87)",
              // dark: "rgb(100, 141, 174)",
              // light: "rgb(166, 212, 250)"
              },
            // secondary: {
            //   main:
            // }
          },
        }),
      [stateDarkTheme],
    );

  
  const didMountRef = useRef(false)
  useEffect(() => {
    const prefersDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    console.log(didMountRef)
    if (!didMountRef.current) {
      console.log(prefersDarkMode)
      gtm();
      if(prefersDarkMode){
        console.log(prefersDarkMode)
        dispatch(appToggleTheme(true))
      };
      didMountRef.current = true
    }
  });

 return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
        <div>
          <div id="menu">
          <SimpleMenu darkTheme={stateDarkTheme}/>
          </div>
          <Switch>
            <Route path="/globe">
              <GlobePage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    );
}

export default App;

