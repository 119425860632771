import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { userUpdateScore} from 'src/redux/actions/userUpdateScore';
import { gameDialogAction } from "src/redux/actions/gameDialogAction";
import { updateProgressArray } from "src/redux/actions/updateProgressArray";
import { defineNewQuizState } from 'src/helpers/defineNewQuizState';
import { updateFocusCountry } from 'src/redux/actions/gameUpdateFocusCountry';
import { addToMarkerList } from 'src/redux/actions/gameAddToMarkerList';
import { parseDistanceCountryToMarker} from 'src/helpers/parseDistanceCountryToMarker';

interface props {
    done: boolean,
    score: number
}

export const NextButton = (props:props) => {
  const stateScore = useSelector((state:any) => state.user.score);
  
  const stateProgressArray = useSelector((state:any) => state.user.progressArray);
  const newScore = props.score + stateScore;
  const dispatch = useDispatch()

  const handleClick = (newScore:number) => {
    dispatch(userUpdateScore(newScore));
    dispatch(gameDialogAction(false));
    const { newProgressArray, newCurrentCountry} = defineNewQuizState(stateProgressArray);
    dispatch(updateProgressArray(newProgressArray));
    dispatch(updateFocusCountry(parseDistanceCountryToMarker(newCurrentCountry)));
    dispatch(addToMarkerList(parseDistanceCountryToMarker(newCurrentCountry)));
  }

    return (
    <Fragment>
      { (props.done) &&
      <Button variant="contained" color="primary" onClick={() => handleClick(newScore)}>Next Country</Button>
    }
    </Fragment>
    );
  }

export default NextButton
  