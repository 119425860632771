import React, { FunctionComponent } from 'react';
import capitals from "src/assets/capitals";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { userNameChange } from "src/redux/actions/userNameChange";
import { userCountryChange } from "src/redux/actions/userCountryChange";
import { createProgressArray } from "src/redux/actions/createProgressArray";
import { appDialogAction } from "src/redux/actions/appDialogAction";
import { updateFocusCountry } from "src/redux/actions/gameUpdateFocusCountry";
import addToMarkerList from 'src/redux/actions/gameAddToMarkerList';

import {InfoDialog} from "./infoDialog";
import Country from "src/models/Country";
import parseCountryToMarker from 'src/helpers/parseCountryToMarker';
import { defineNumberOfSteps } from 'src/redux/actions/userDefineNumberOfSteps';

interface Props {
    open: boolean;
//     // onClick: (action: any) => void;
  }
  
  export const DetailsDialog: FunctionComponent<Props> = (props) => {
    

    const open = props.open;
    const stateName = useSelector((state:any) => state.user.name);
    const stateNumberOfSteps = useSelector((state:any) => state.user.numberOfSteps);
    const [name, setName] = React.useState<string>(stateName);
    const [numberOfSteps, setNumberOfSteps] = React.useState<number>(stateNumberOfSteps);

    const stateCountry = useSelector((state:any) => state.user.country);
    const [homeCountryISO, setHomeCountryISO] = React.useState<string>(stateCountry.iso2Code);
    

      const handleClose = () => {
        dispatch(appDialogAction(false));
      };
      
    const dispatch = useDispatch();

    const handleUpdate = () => {
      // if slider not used, than numberOfSteps = undefined
      // if stateNumberOfSteps !== undefined && numberOfSteps === undefined then stateNumberOfSteps
      // if stateNumberOfSteps === undefined && numberOfSteps !== undefined then numberOfSteps
      // if stateNumberOfSteps === undefined && numberOfSteps === undefined then capitals.length
      const stepsToDispatch = numberOfSteps ? numberOfSteps : stateNumberOfSteps ? stateNumberOfSteps : capitals.length
      
      handleClose();
      dispatch(userNameChange(name));
      const homeCountry:Country = capitals.filter(c => c.iso2Code === homeCountryISO)[0];
      dispatch(defineNumberOfSteps(stepsToDispatch));
      dispatch(userCountryChange(homeCountry));
      console.log(homeCountry, stepsToDispatch);
      dispatch(createProgressArray(homeCountry, stepsToDispatch));
      dispatch(updateFocusCountry(parseCountryToMarker(homeCountry)));
      dispatch(addToMarkerList(parseCountryToMarker(homeCountry)));
      
    };
    const handleCancel = () => {
        handleClose();
        setName(stateName)
    };

    const handleHomeCountryChange = (event: React.ChangeEvent<{ value: string }>) => {
      setHomeCountryISO(event.target.value);
    };

    function valuetext(value:number) {
      return `${value} countries`;
    }
    const handleSliderChange = (event:any, newValue:any) => {
      setNumberOfSteps(+newValue);
    };

    return (
        <div>
           <Dialog 
           open={open} 
           onClose={handleClose} 
           aria-labelledby="form-dialog-title"
           disableBackdropClick={(name === '' || homeCountryISO === ('' || undefined)) ? true : false}
           disableEscapeKeyDown={(name === '' || homeCountryISO === ('' || undefined)) ? true : false}
           >
        <DialogTitle id="form-dialog-title">User Details
        <InfoDialog />
        </DialogTitle >
        <DialogContent>
          <DialogContentText>
            Please enter your name and country:
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="name"
            label="Name"
            type="text"
            onChange={e => setName(e.target.value )}
            defaultValue={name ? name : '' }
            fullWidth
          />

          <TextField
                  // autoFocus
                  label="Home Country"
                  // helperText="Select Home Country"
                  select
                  margin="dense"
                  defaultValue={homeCountryISO ? homeCountryISO : ''}
                  onChange={handleHomeCountryChange}
                  fullWidth
                >
                  {capitals.map(country => (
                    <MenuItem key={country.iso2Code} value={country.iso2Code}>{country.name}</MenuItem>
                  ))}
          </TextField>
          <DialogContentText>
          Number of Steps to take you Home:
          </DialogContentText>
          <Slider
          defaultValue={ stateNumberOfSteps > 0 ? stateNumberOfSteps : capitals.length}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={10}
          marks
          // min={1} // temporary for testing end-phase
          min={10}
          max={capitals.length}
          onChange={handleSliderChange}
          disabled={stateNumberOfSteps > 0}
          />
            

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button 
          onClick={handleUpdate} 
          color="primary"
          disabled={(name === '' || homeCountryISO === ('' || undefined)) ? true : false}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog> 
        </div>
    )

  }
  export default DetailsDialog;
